var content = {
    pl: {
        header: 'Portal do utrzymania porządku w domu',
        title: 'Nasi Goście\nPolegają na Tobie.',
        services: 'Twoje usługi',
    }
}


export default function getContent(lang) {
    if (lang === 'pl') {
        return content.pl
    } else {
        throw new Error('Unsupported language')
    }
}