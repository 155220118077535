import { app } from '@/main.js'

import { authGet } from './base.js'

export async function getStays () {
    // Returns a list of all the stays a user has booked
    try {
        var headers = { headers: { 'Authorization': 'Token ' + app.config.globalProperties.$cookies.get('token') } }
      const { data } = await authGet('api/v1/stays/', headers)
      return [null, data]
      } catch (err) {
          return [err, null]
      }
}
  
export async function getStayDetail (stay) {
    // Returns the detailed information about a stay
    try {
      var headers = { headers: { 'Authorization': 'Token ' + app.config.globalProperties.$cookies.get('token') } }
      const { data } = await authGet(`api/v1/stay/${stay}/`, headers)
      return [null, data]
      } catch (err) {
          return [err, null]
      }
  }