// /import { app } from '@/main.js'

const state = {
    stays: [],
    stayDetails: {},
};

const mutations = {
    setStays(state, stays) {
        // Set the stays object to the stays array
        state.stays = stays;
    },
    addStayDetail(state, details) {
        // Add the stay details to the stayDetails object
        state.stayDetails[details.id] = details;
    }
}

import { getStays, getStayDetail } from '@/services/stays.js'

const actions = {
    async syncStays ({ commit }) {
      // Call the getStays function and await its response    
      const [error, stays] = await getStays()

      // If an error occurs, throw an error with the error message
      if (error) {
        throw new Error(error.response.data.detail)
      }

      // If no error occurs, commit the setStays mutation with the stays as an argument
      commit('setStays', stays)
    },
    async syncStayDetail ({ commit }, stay) {
      // Call the getStayDetail function and await its response
      const [error, detail] = await getStayDetail(stay)

      // If an error occurs, throw an error with the error message
      if (error) {
        throw new Error(error.response.data.detail)
      }

      // If no error occurs, commit the setStayDetail mutation with the stay and detail as arguments
      commit('addStayDetail', detail)
  },
  async syncAllStayDetails({ state, dispatch }) {
    // Loop through the stays array
    for (const stay of state.stays) {
      // For each stay, call the syncStayDetail action with the stay as an argument
      try {
        await dispatch('syncStayDetail', stay.id)
      } catch (error) {
        console.error(error) // continue regardless of error
      }
    }
  }       
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};