<template>
    <van-uploader preview-size="300" :accept="'image/*'" :before-read="beforeRead" :after-read="uploadFile" @delete="resetData" :max-count="1" v-model="files">
        <div class="img-container">
            <img :key="exampleImg" :src="exampleImg" v-if="exampleImg" class="example-img">
            <Icon class="camera-icon" size="48">
                <Camera20Filled />
            </Icon>
        </div>
</van-uploader>
</template>

<script>
import { showToast } from 'vant';
import { Camera20Filled } from '@vicons/fluent'
import { Icon } from '@vicons/utils'

export default {
    name: 'PhotoUpload',
    components: { Camera20Filled, Icon },
    data: () => ({
        files: [],
        fileId: null,
    }),
    props: {
        exampleImg: {
            type: String,
        },
    },
    methods: {
        uploadFile: async function (file) {
            this.$emit('upload', file)
        },
        beforeRead: (file) => {
            if (file.type !== 'image/jpeg' & file.type !== 'image/png' & file.type !== 'image/webp') {
                showToast('Please upload an image in jpg format');
                return false;
            }
            return true;
        },
        resetData: function () {
            // resets the input data
            this.files = []
            this.fileId = null
        },
    }
}
</script>

<style scoped>
.example-img {
    width: calc(100vw - 48px);
    height: 100%;
    object-fit: cover;
    filter: grayscale(40%);
    border-radius: 15px;
}
.img-container {
    width: calc(100% - 24px);
    height: 300px;
    overflow: hidden;
}
.camera-icon {
    position: absolute;
    color: var(--primary);
    font-size: 2rem;
    cursor: pointer;
    left: calc(50% - 24px);
    bottom: calc(50% - 24px);
}
</style>