<template>
    <page-header :actions="[]" title="Noma Homes" />
    <van-row>
        <hero-section />
    </van-row>
    <van-row>
        <h3 class="upcoming-trips-title">Upcoming Trips</h3>
    </van-row>
    <van-row v-for="stay in stays" :key="stay.id">
        <property-card v-bind="stay" @click="navigateToStay(stay.id)"></property-card>
    </van-row>
</template>

<script>
import PageHeader from '@/components/pageheader/PageHeader.vue'
import HeroSection from '@/components/landingpage/HeroSection.vue'
import PropertyCard from '@/components/landingpage/propertycard/PropertyCard.vue'

import { mapActions } from 'vuex'
import { waitForCookie } from '@/services/base'

export default {
    name: 'LandingPage',
    methods: {
        ...mapActions('stays', ['syncStays', 'syncStayDetail', 'syncAllStayDetails']),
        navigateToStay(id) {
            // Use the Vue Router to navigate to the chat thread page
            this.syncStayDetail(id) // get the stay details
            this.$router.push({ name: "stays", params: { id } });
        },
    },
    components: {
        PageHeader,
        HeroSection,
        PropertyCard
    },
    mounted: async function() {
        if (!this.isAuthenticated) {
            // wait for the cookie to be set
            try {
                await waitForCookie('token', 5)
            } catch {
                // if the cookie is not set, redirect to the signin page
                console.log('Cookie not set redirecting to signin')
                this.$router.push({name: 'signin'});
            }
        }
        this.syncStays() // get all stays
        this.syncAllStayDetails() // get all stay details
    },
    computed: {
        stays() {
            return this.$store.state.stays.stays
        },
        isAuthenticated: function () {
            return this.$store.getters['auth/isAuthenticated']
        },
    },
    watch: {
        stays: function () {
            // When the stays are updated, sync all the stay details
            this.syncAllStayDetails()
        }
    }
}
</script>

<style scoped>
.upcoming-trips-title {
    text-transform: uppercase;
    margin-left: 24px;
    margin-top: 24px;
    margin-bottom: 0;
    padding-bottom: 0;
    font-weight: 700;
    color: var(--black);
    font-family: 'DM Sans';
}
</style>

