<template>
        <van-row>
            <h3 class="title">{{  content.cleaningSummary }}</h3>
        </van-row>
        <van-row class="section" justify="center">
            <van-cell-group class="cell-group">
                <van-cell :title="content.clean">
                    <template #value>
                        <h4 class="bold-text">{{ housekeepingService.serviceType.name }}</h4>
                    </template>
                </van-cell>
                <van-cell :title="content.status">
                    <template #value>
                        <h4 :style="{'color': housekeepingService.status === 'cancelled' ? 'red' : 'var(--primary)' }" class="status-text">{{ content.statusOptions[housekeepingService.status] }}</h4>
                    </template>
                </van-cell>
                <van-cell :title="content.openFrom" :value="formatStayDateTime(housekeepingService.windowStart)"></van-cell>
                <van-cell :title="content.finishBy" :value="formatStayDateTime(housekeepingService.windowEnd)"></van-cell>
                <van-cell :title="content.durationMinutes" :value="parseInt(housekeepingService.serviceType.durationMinutes)"></van-cell>
                <van-cell :title="content.pays" :value="formatCurrencyAmount(housekeepingService.serviceType.price, housekeepingService.serviceType.priceCurrency)"></van-cell>
            </van-cell-group>
        </van-row>
        <van-row>
            <h3 class="title">{{ content.locationAccess }}</h3>
        </van-row>
        <van-row class="section" justify="center">
            <van-cell-group class="cell-group">
                <van-cell :title="housekeepingService.stay.address">
                    <template #value>
                        <Icon @click="openLink(housekeepingService.stay.googleMapsLink)" color="black" size="36"><directions-24-regular></directions-24-regular></Icon>
                    </template>
                </van-cell>
                <van-cell :title="content.buildingAccessCode" :value="housekeepingService.buildingAccessCode"></van-cell>
                <van-cell :title="content.unitAccessCode" :value="housekeepingService.unitAccessCode"></van-cell>
            </van-cell-group>
        </van-row>
        <van-row>
            <h3 class="title">{{  content.nomaStaffContact }}</h3>
        </van-row>
        <van-cell-group class="cell-group">
            <van-cell :title="`${housekeepingService.stay.conciergeName}`">
                <template #value>
                    <Icon @click="openLink(housekeepingService.stay.whatsappLink)" color="green" size="36"><whatsapp-square></whatsapp-square></Icon>
                </template>
            </van-cell>
        </van-cell-group>
        <van-row>
            <h3 class="title">{{ content.guestInfo }}</h3>
        </van-row>
        <van-cell-group class="cell-group">
            <van-cell :title="content.guestName" :value="`${housekeepingService.stay.guest}`"></van-cell>
        </van-cell-group>
        <van-row class="submit-button">
            <van-button
                :disabled="housekeepingService.status === 'cancelled' || housekeepingService.status === 'completed'"
                class="gold-button-shadow"
                block
                round
                type="primary"
                native-type="submit"
                @click="$emit('start')"
            >
                <p class="button-text">{{ content.start }}</p>
            </van-button>
        </van-row>
</template>

<script>
import { formatCurrencyAmount } from '@/utils/currency';
import { formatStayDateTime } from '@/utils/date'
import { Icon } from '@vicons/utils'
import { Directions24Regular } from '@vicons/fluent'
import { WhatsappSquare } from '@vicons/fa'

export default {
    name: "CleanSummary",
    props: {
        housekeepingService: {
            type: Object,
            required: true
        },
        content: {
            type: Object,
            required: true
        }
    },
    components: {
        Icon,
        Directions24Regular,
        WhatsappSquare
    },
    methods: {
        formatCurrencyAmount,
        formatStayDateTime,
        openLink(url) {
            window.open(url, '_blank');
        },
    },
}
</script>


<style scoped>
.title {
    text-transform: uppercase;
    margin: 24px 24px 12px 24px;
    padding-bottom: 0;
    font-weight: 700;
    color: var(--black);
    font-family: 'DM Sans';
}

.section {
    width: 100vw;
}

.cell-group {
    width: calc(100vw - 24px);
    padding: 12px;
    margin-top: 12px;
}

.bold-text {
    font-weight: 700;
    padding: auto;
    margin: 0;
    font-size: 18px;
    font-family: 'DM Sans';
    text-transform: uppercase;
    text-decoration: underline;
}

.status-text {
    font-weight: 700;
    padding: auto;
    margin: 0;
    font-size: 18px;
    font-family: 'DM Sans';
    text-transform: uppercase;
}

.end {
    height: 64px;
    width: 100vw;
}

.gold-button-shadow {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.12);
    transition: box-shadow 0.3s ease-in-out;
}

.button-text {
    font-weight: 600;
    font-size: 16px;
    color: var(--black);
}

.submit-button {
    padding: 12px;
    padding-top: 24px;
    padding-bottom: 24px;
}
</style>