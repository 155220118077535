<template>
    <sign-in></sign-in>
</template>

<script>
import { showFailToast, showLoadingToast, closeToast, showSuccessToast } from "vant";
import { mapActions } from 'vuex'
import SignIn from './SignIn.vue'

export default {
    name: 'MagicLink',
    components: {
        SignIn
    },
    methods: {
        ...mapActions('auth', ['signInWithMagicLink']),
    },
    mounted: function () {
        showLoadingToast('Verifying Magic Link...');
        this.signInWithMagicLink(this.$route.params.token)
            .then(() => {
                closeToast()
                showSuccessToast('You are now signed in.')
                this.$router.push({name: 'home'})
            })
            .catch((error) => {
                console.log(error)
                closeToast()
                showFailToast(`That link isn't valid.`)
            })
    }    
}
</script>