<template>
    <van-row>
        <h3 class="title">{{ content.cleaningChecklist}}</h3>
    </van-row>
    <van-row v-for="step in steps" :key="step.id" class="section" justify="center">
        <van-cell-group class="cell-group">
            <van-cell :title="step.name" :value="step.description"></van-cell>
        </van-cell-group>
    </van-row>
    <van-row class="submit-button">
        <van-button
            class="gold-button-shadow"
            block
            round
            type="primary"
            native-type="submit"
            @click="$emit('finish')"
        >
            <p class="button-text">{{  content.finished }}</p>
        </van-button>
    </van-row>
</template>

<script>
export default {
    name: "CleaningSteps",
    props: {
        steps: {
            type: Array,
            required: true
        },
        content: {
            type: Object,
            required: true
        }
    },
}
</script>


<style scoped>

.title {
    text-transform: uppercase;
    margin: 24px 24px 12px 24px;
    padding-bottom: 0;
    font-weight: 700;
    color: var(--black);
    font-family: 'DM Sans';
}
.section {
    width: 100vw;
}
.cell-group {
    width: calc(100vw - 24px);
    padding: 12px;
    margin-top: 12px;
}
.gold-button-shadow {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.12);
    transition: box-shadow 0.3s ease-in-out;
}

.button-text {
    font-weight: 600;
    font-size: 16px;
    color: var(--black);
}

.submit-button {
    padding: 12px;
    padding-top: 24px;
    padding-bottom: 24px;
}

</style>