import axios from 'axios'
import { app } from '@/main.js'

export const baseURL = 'https://property-management.app.myairhome.com/'

//export const baseURL = 'http://localhost:8000/'

export const api = axios.create({
baseURL: baseURL,
timeout: 50000,
headers: {
  'Content-Type': 'application/json',
    }
  })

export const formdataapi = axios.create({
baseURL: baseURL,
timeout: 50000,
headers: {
    'Content-Type': 'multipart/form-data',
    }
})

export const authApi = axios.create({
baseURL: baseURL,
timeout: 50000,
headers: {
  'Content-Type': 'application/json',
    }
})

export async function waitForCookie(cookieName, maxAttempts = 10) {
    // wait for cookie to be available before continuing
    let attempts = 0;

    return new Promise((resolve, reject) => {
        const intervalId = setInterval(() => {
            attempts++;

            if (app.config.globalProperties.$cookies.get(cookieName)) {
                clearInterval(intervalId);
                resolve();
            } else if (attempts > maxAttempts) {
                clearInterval(intervalId);
                reject(new Error(`Cookie ${cookieName} not set within the maximum number of attempts.`));
            }
        }, 1000); // check every second
    });
}
  
authApi.interceptors.request.use(async (config) => {
    const token = await waitForCookie('token', 30); // or whatever parameters are appropriate
  if (token) {
        config.headers.Authorization = `Token ${token}`;
    }
    return config;
}, (err) => {
    return Promise.reject(err);
});
    



// BASE REQUESTS

api.interceptors.response.use((response) => {
  // Response interceptor for API calls that refreshes the token if it is expired
  return response
}, async function (error) {
  if (error.response.status === 403) {
    app.config.globalProperties.$cookies.remove('token')
  }
  throw( error );
});

export function get(url, params) {
  return api.get(url, params)
}

export function post(url, data, params) {
  return api.post(url, data, params)
}

export function put(url, data, params) {
  return api.put(url, data, params)
}

export function del(url, data, params) {
  return api.delete(url, data, params)
}

export function formdata(url, data, params) {
  return formdataapi.post(url, data, params)
}

// auth

export function authGet(url, params) {
  return authApi.get(url, params)
}

export function authPost(url, data, params) {
  return authApi.post(url, data, params)
}

export function authPut(url, data, params) {
  return authApi.put(url, data, params)
}

export function authDel(url, data, params) {
  return authApi.delete(url, data, params)
}