
import { dedupeObjects } from '@/utils/chatUtils.js'

const state = {
  messages: {
      21: [
      ]
  }
};

const mutations = {
  createOrUpdateMessage: function (state, data) {
    // if a message with the same id exists we update it, otherwise we replace it
    var messages = [...state.messages[data.thread]]
    messages.push(data.message)
    var dedupedMessages = dedupeObjects(messages)
    state.messages[data.thread] = dedupedMessages
  },
  addMessage: function (state, data) {
    // add a single message. Data format {thread: *thread_id, message: *message}
    this.commit("chat/createOrUpdateMessage", data)
  },
  addMessages: function (state, data) {
    // add a list of messages to a thread. Data format {thread: *thread_id, messages: [*messages]}
    var messages = [...state.messages[data.thread]]
    messages.push(...data.messages)
    var dedupedMessages = dedupeObjects(messages)
    state.messages[data.thread] = dedupedMessages
  },
  setMessages: function (state, data) {
    // force the cache to be set from a list of messages
    state.messages[data.thread] = data.messages
  }
};

import { getThreadMessages, sendMessage } from '@/services/chat'

const actions = {
  async syncThreadMessages ({ commit }, thread) {
    try {
      // Call the getThreadMessages function and await its response
      const [error, messages] = await getThreadMessages(thread)

      // If an error occurs, throw an error with the error message
      if (error) {
        throw new Error(error.response.data.detail)
      }

      // If no error occurs, commit the setMessages mutation with the thread and messages as arguments
      commit('setMessages', { thread: thread, messages: messages })
    } catch (error) {
      // If an error occurs during the try block, log the error message to the console
      console.error(error.message)
    }
  },

  async fetchThreadMessages ({ commit }, thread) {
    try {
      // Call the getThreadMessages function and await its response
      const [error, messages] = await getThreadMessages(thread)

      // If an error occurs, throw an error with the error message
      if (error) {
        throw new Error(error.response.data.detail)
      }

      // If no error occurs, commit the addMessages mutation with the thread and messages as arguments
      commit('addMessages', { thread: thread, messages: messages })
    } catch (error) {
      // If an error occurs during the try block, log the error message to the console
      console.error(error.message)
    }
  },

  async createMessage({ commit }, { thread, message }) {
    try {
      // Add the message to the store using the addMessage mutation with thread and message as arguments
      commit("addMessage", { thread, message });

      // Send the message to the backend and await the response
      const [, response] = await sendMessage(message);

      // Return the response from sendMessage
      return response;
    } catch (error) {
      // If an error occurs during the try block, log the error message to the console
      console.error(error.message);
    }
  },
};

const getters = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};