import { login, loginWithMagicLink } from '@/services/auth';
import { app } from '@/main.js'
import { getUserProfile } from '@/services/auth'

const state = {
  token: null,
  user: {}
};

const mutations = {
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_USER(state, user) {
    state.user = user
  },
};


const actions = {
  async signInWithMagicLink({ dispatch }, token) {
    const error = await loginWithMagicLink(token);
    if (error === null) {
      try {
        await dispatch('fetchUserProfile');
      } catch (err) {
        console.error(err);
        // handle the error here
      }
    } else {
      throw error;
    }
  },
  async signIn({ dispatch }, { email, password }) {
    const error = await login(email, password);
    if (error === null) {
      try {
        await dispatch('fetchUserProfile');
      } catch (err) {
        console.error(err);
        // handle the error here
      }
    } else {
      throw error;
    }
  },
  async fetchUserProfile({ commit }) {
    const [error, profile] = await getUserProfile()

    if (error) {
      throw new Error(error)
    }
    commit('SET_USER', profile)
  },
};

const getters = {
  isAuthenticated() {
    return !!app.config.globalProperties.$cookies.get('token');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};