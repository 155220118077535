<template>
    <h3 class="title">{{ content.cleaningDamage }}</h3>
    <p class="text">{{ content.reportDamage }}</p>
    <van-row justify="center">
        <Icon class="icon" @click="openLink(housekeepingService.stay.whatsappLink)" color="green" size="64"><whatsapp-square></whatsapp-square></Icon>
    </van-row>
    <van-button class="button" type="primary" round block @click="this.$emit('finish')">{{ content.finished }}</van-button>
</template>

<script>
import { Icon } from '@vicons/utils'
import { WhatsappSquare } from '@vicons/fa'

export default {
    name: 'ReportDamage',
    components: {
        Icon,
        WhatsappSquare
    },
    props: {
        housekeepingService: {
            type: Object,
        },
        content: {
            type: Object,
        }
    },
    methods: {
        openLink(url) {
            window.open(url, '_blank');
        },
    }
}
</script>

<style scoped>
.title {
    text-transform: uppercase;
    margin: 24px 24px 12px 24px;
    padding-bottom: 0;
    font-weight: 700;
    color: var(--black);
    font-family: 'DM Sans';
}
.text {
    margin: 0 24px 24px 24px;
}
.button {
    margin: 0 12px 12px 12px;
    width: calc(100% - 24px);
}
.icon {
    margin: 0 24px 24px 24px;
}
</style>