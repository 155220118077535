<template>
    <div class="hero-container">
        <img rel="preload" class="hero-img" :src="img" />
        <h2 class="hero-title">{{ title }}</h2>
    </div> 
</template>

<script>
export default {
    name: 'HeroSection',
    props: {
        title: {
            type: String,
            default: 'Live The Extraordinary'
        },
        img: {
            type: String,
            default: 'https://images.pexels.com/photos/2426546/pexels-photo-2426546.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
        }
    }
}
</script>

<style scoped>
.hero-img {
    min-width: 100vw;
    filter: brightness(0.8) grayscale(0.5);
    max-height: 300px;
    object-fit: cover;
}

.hero-container {
    max-width: 100vw;
    overflow: hidden;
    position: relative;
}

.hero-title {
    position: absolute;
    left: 24px;
    top: 180px;
    color: white;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
    font-size: 36px;
    white-space: pre-wrap;
    font-family: 'Open Sans';
}

</style>