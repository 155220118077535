import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { ObserveVisibility } from 'vue-observe-visibility'
import VuePannellum from 'vue-pannellum'
import CountryFlag from 'vue-country-flag-next'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'
import PusherPlugin from './plugins/pusher'
import { Vue3Mq } from "vue3-mq";
import errorHandler from './errorHandler'
export const app = createApp(App)

app.use(VueCookies, {
    expireTimes: "30d",
    path: "/",
    domain: "",
    secure: true,
    sameSite: "None"
});

app.component('VPannellum', VuePannellum)

app.component('country-flag', CountryFlag)

app.use(store).use(router).mount('#app')

app.directive('observe-visibility', ObserveVisibility)

app.use(VueAxios, axios)

const apiKey = '30ec875648065c9d6f16'

app.use(PusherPlugin, { apiKey, cluster: 'eu' })

app.use(Vue3Mq, { preset: 'devices' });

app.config.errorHandler = errorHandler

app.config.globalProperties.window = window



// vant ui

import './global.css';
import 'vant/lib/index.css';
import { Locale } from 'vant';
import enUS from 'vant/es/locale/lang/en-US';

Locale.use('en-US', enUS);

import { Empty, NoticeBar, Step, Steps, Cell, Uploader, Image, Row, Col, List, Popover, Badge, Icon, NavBar, CellGroup, Field, Calendar, Picker, Popup, Stepper, BackTop, Button, ActionSheet, Tab,  TabbarItem, Tabs, PasswordInput, NumberKeyboard, DatePicker, TimePicker, Image as VanImage, Swipe, SwipeItem, Progress } from 'vant';
import './registerServiceWorker'

app.use(Uploader)
app.use(Image)
app.use(Row)
app.use(Col)
app.use(List)
app.use(Popover)
app.use(Badge)
app.use(Icon)
app.use(NavBar)
app.use(Calendar)
app.use(Picker)
app.use(Popup)
app.use(Stepper)
app.use(BackTop)
app.use(Button)
app.use(ActionSheet)
app.use(Tab)
app.use(TabbarItem)
app.use(Tabs)
app.use(PasswordInput)
app.use(NumberKeyboard)
app.use(DatePicker)
app.use(TimePicker)
app.use(VanImage)
app.use(Swipe)
app.use(SwipeItem)
app.use(Progress)
app.use(Field)
app.use(CellGroup)
app.use(Cell)
app.use(Step)
app.use(Steps)
app.use(NoticeBar)
app.use(Empty)


