<template>
  <van-nav-bar ref="navBar" class="my-nav-bar" @click-left="$router.back()" left-arrow :title="title" :border="false">
    <template #right>
      <van-popover v-model:show="showPopover" :actions="actions" placement="left-start" @select="onSelect">
        <template #reference>
          <Icon color="white" size="22">
            <MoreVertical28Filled />
          </Icon>
        </template>
      </van-popover>
    </template>
  </van-nav-bar>
</template>

<script>
import { MoreVertical28Filled } from "@vicons/fluent";
import { Icon } from "@vicons/utils";

export default {
  components: {
    Icon,
    MoreVertical28Filled,
  },
  props: {
    title: {
      type: String,
      default: 'Chat'
    },
    color: {
      type: String,
      default: '#081A39'
    }
  },
  data: function () {
    return {
      actions: [
        { text: "Complaint" },
        { text: "Damage" },
        { text: "Update Details" },
      ],
    };
  },
  watch: {
    color(newColor) {
      this.updateNavBarBackground(newColor);
    },
  },
  mounted() {
    this.updateNavBarBackground(this.color);
  },
  methods: {
    updateNavBarBackground(color) {
      this.$refs.navBar.$el.style.setProperty('--van-nav-bar-background', color, 'important');
    },
  },
};
</script>
