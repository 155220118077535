import router from './router'

export default function errorHandler (error, vm, info) {
  console.error('Global Error Handler:', error, vm, info)
  if (error.response && error.response.status === 401) {
    // Redirect to sign-in screen if authentication fails
    router.push('/signin')
  } else {
    // Redirect to home screen for all other errors
    router.push('/')
  }
}