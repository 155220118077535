import { app } from '@/main.js'

import { get, post } from './base.js'

export async function getHousekeepingServices () {
    // Returns a list of all the users' housekeeping services
    try {
      var headers = {headers: {'Authorization': 'Token ' + app.config.globalProperties.$cookies.get('token')}}
      const { data } = await get('api/v1/housekeepingservices/', headers)
      return [null, data]
      } catch (err) {
          return [err, null]
      }
}

export async function uploadFile(file) {
    try {
        console.log(`uploadFile: ${file}`)
        var headers = {headers: {'Authorization': 'Token ' + app.config.globalProperties.$cookies.get('token'), 'Content-Type': 'multipart/form-data'}}
        const formData = new FormData()
        formData.append('file', file.file)
        const { data } = await post(`api/v1/media_file_upload/`, formData, headers)
    return [null, data];
  } catch (error) {
      console.log(error)
      console.error(error.response.data)
    return [error, null];
  }
}

export async function createOrUpdateCleaningPhoto (photo) {
    // Updates a housekeeping photo
    try {
        var headers = { headers: { 'Authorization': 'Token ' + app.config.globalProperties.$cookies.get('token') } }
        const { data } = await post(`api/v1/cleaningphotos/`, photo, headers)
        return [null, data]
        } catch (err) {
            return [err, null]
        }
}
