<template>
    <page-header :title="content.header"></page-header>
    <hero-section :title="content.title"></hero-section>
    <van-row>
        <h3 class="title">{{ content.services }}</h3>
    </van-row>
    <van-row v-for="service in housekeepingServices" :key="service.id">
        <service-card @click="navigateToService(service.id)" v-bind="service"></service-card>
    </van-row>
</template>

<script>
import HeroSection from "@/components/housekeepinglandingpage/HeroSection.vue";
import PageHeader from '@/components/pageheader/PageHeader.vue'
import ServiceCard from '@/components/housekeepinglandingpage/ServiceCard.vue'
import getContent from '@/content/housekeepinglandingpage.js'

import { mapActions } from 'vuex'

export default {
    name: "CleanerView",
    components: {
        HeroSection,
        PageHeader,
        ServiceCard
    },
    data: () => ({
        
    }),
    mounted() {
        this.syncHousekeepingServices()
    },
    methods: {
        ...mapActions('housekeeping', ['syncHousekeepingServices']),
        navigateToService(id) {
            // Use the Vue Router to navigate to the chat thread page
            this.$router.push({ name: "housekeepingservices", params: { id } });
        },
    },
    computed: {
        content: () => getContent('pl'),
        housekeepingServices: function () {
            return this.$store.state.housekeeping.housekeepingServices
        }
    }
}
</script>

<style scoped>
.title {
    text-transform: uppercase;
    margin-left: 24px;
    margin-top: 24px;
    margin-bottom: 0;
    padding-bottom: 0;
    font-weight: 700;
    color: var(--black);
    font-family: 'DM Sans';
}
</style>