<template>
  <van-nav-bar color="black" :border="false">
    <template #left>
      <img rel="preload" class="logo" src="@/assets/icon.png" />
    </template>
    <template #right>
      <van-popover
        v-model:show="showPopover"
        :actions="actions"
        placement="left-start"
        @select="onSelect"
      >
        <template #reference>
          <Icon v-if="actions && actions.length > 0" color="white" size="22">
            <MoreVertical28Filled />
          </Icon>
        </template>
      </van-popover>
    </template>
  </van-nav-bar>
</template>

<script>
import { MoreVertical28Filled } from "@vicons/fluent";
import { Icon } from "@vicons/utils";

export default {
  components: {
    Icon,
    MoreVertical28Filled,
  },
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
  }
};
</script>

<style scoped>
.app-name {
  color: var(--white);
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 16px;
}
.logo {
  max-width: 30px;
  height: auto;
  margin-right: 12px;
  min-width: 30px;
}
</style>