<template>
  <div class="sign-in">
    <van-row justify="center">
      <img class="logo" src="@/assets/logo.svg" alt="Noma Homes" />
    </van-row>
    <van-row justify="center">
      <div class="card">
        <van-form @submit="handleSubmit">
          <h2 class="title">Welcome Home</h2>
          <van-field
            v-model="email"
            label="Email"
            required
            input-align="left"
            placeholder="Enter your email"
          ></van-field>
          <van-field
            v-model="password"
            type="password"
            label="Password"
            required
            input-align="left"
            placeholder="Enter your password"
          ></van-field>
          <div class="submit-container">
            <van-button
              class="gold-button-shadow"
              block
              round
              type="primary"
              native-type="submit"
              @click="handleSubmit"
            >
              <p class="button-text">Sign In</p>
            </van-button>
          </div>
        </van-form>
        <div class="signup-container">
          <p>
            Don't have an account?
            <a class="link" href="https://tally.so/r/mKexBg" @click="openLink('https://tally.so/r/mKexBg')">Apply Here</a>
          </p>
        </div>
      </div>
    </van-row>
  </div>
</template>

<script>
import { showToast, showFailToast, showLoadingToast, closeToast } from "vant";

export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  computed: {
    isAuthenticated: function () {
      return this.$store.getters['auth/isAuthenticated']
    },
  },
  watch: {
    isAuthenticated: function () {
      if (this.isAuthenticated) {
        this.$router.push({name: 'home'});
      }
    },
  },
  methods: {
    sendEmail() {
      const email = "apply@noma.homes";
      const subject = `Apply to join`;
      const body = `Hi there,\n\nI would like to join Noma Homes to own and rent quality global real estate.\n\nThank you.`;
      const mailto = `mailto:${email}?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;
      window.location.href = mailto;
    },
    openLink(url) {
      window.open(url, '_blank');
    },
    async handleSubmit() {
      try {
        showLoadingToast({ duration: 10000, forbidClick: true, message: "Loading..." });
        await this.$store.dispatch("auth/signIn", {
          email: this.email,
          password: this.password,
        });
        setTimeout(() => showToast('Logged in'), 1800);
        setTimeout(() => closeToast(), 1700);
        setTimeout(() => this.$router.push({name: 'home'}), 2000);
      } catch (error) {
        closeToast();
        let errorMessage = "An error occurred. Please try again.";
        try {
          if (error.response && error.response.data) {
            if (error.response.data.non_field_errors) {
              errorMessage = error.response.data.non_field_errors[0];
            } else if (error.response.data.detail) {
              errorMessage = error.response.data.detail;
            }
          }
        } catch (error) {
          console.log(error);
        }
        showFailToast({ message: errorMessage, wordBreak: "break-word" });
      }
    },
  },
};
</script>

<style scoped>
.sign-in {
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  justify-content: center;
  background-image: linear-gradient(
    var(--dark-bg-color-start),
    var(--dark-bg-color-end)
  );
  position: relative;
  padding-top: 2rem;
}

.logo-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.logo {
  max-width: 240px;
  height: auto;
}

.card {
  max-width: 400px;
  padding: 2rem;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  background-color: white;
}

.submit-container {
  margin-top: 24px;
}

.signup-container {
  margin-top: 1rem;
  text-align: center;
  color: var(--text-color);
}
.link {
  color: var(--secondary-color);
  text-decoration: underline;
}
.button-text {
  font-weight: 600;
  font-size: 16px;
  color: var(--black);
}
.gold-button-shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.3s ease-in-out;
}

.title {
  font-weight: 600;
  font-size: 32px;
  text-align: center;
}

.gold-button-shadow:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.15);
}

@media (min-width: 768px) {
  .logo-container {
    top: 4rem;
  }

  .card {
    padding: 4rem;
  }

  .logo {
    max-width: 240px;
  }
}
</style>