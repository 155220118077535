import { createRouter, createWebHistory } from 'vue-router'
import SignIn from '../views/SignIn.vue'
import MessageThread from '@/views/ChatView.vue'
import LandingPage from '@/views/LandingPage.vue'
import StaySummary from '@/views/StaySummary.vue'
import HousekeepingLandingPage from '@/views/HousekeepingLandingPage.vue'
import HousekeepingServicePage from '@/views/HousekeepingServicePage.vue'
import MagicLink from '@/views/MagicLink.vue'

const routes = [
  {
    path: '/home',
    name: 'home',
    component: LandingPage
  },
  // auth
  {
    path: '/',
    name: '/',
    component: SignIn
  },
  {
    path: '/signin',
    name: 'signin',
    component: SignIn
  },
  // chat - unused
  {
    path: '/thread/:id?',
    name: 'thread',
    component: MessageThread,
    props: true,
  },
  // guest stays
  {
    path: '/landing',
    name: 'landing',
    component: LandingPage
  },
  {
    path: '/stays/:id?',
    name: 'stays',
    component: StaySummary
  },
  // housekeeping
  {
    path: '/housekeeping',
    name: 'housekeeping',
    component: HousekeepingLandingPage
  },
  {
    path: '/housekeepingservices/:id?',
    name: 'housekeepingservices',
    component: HousekeepingServicePage
  },
  {
    path: '/magiclink/:token?',
    name: 'magiclink',
    component: MagicLink,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
