<template>
  <nav></nav>
  <router-view />
</template>

<script>

export default {
  name: "App",
  computed: {
    isAuthenticated: function () {
      return this.$store.getters['auth/isAuthenticated']
    },
  },
  created: function () {
    if (this.isAuthenticated) {
      this.$router.push({name: 'home'});
    }
  },
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #222222;
  font-family: "Open Sans", sans-serif;
}
</style>