<template>
    <div :class="status == 'cancelled' ? 'card-cancelled' : 'card'">
    <img :src="stay.img" :alt="`${title}`" class="card-image">
    <div class="card-content">
        <h2 :class="status == 'cancelled' ? 'card-title-cancelled' : 'card-title'">{{ stay.title }}</h2>
        <p :class="status == 'cancelled' ? 'checkin-date-cancelled' : 'checkin-date'">{{ status === 'scheduled' ? formatStayDateTime(cleaningSlot) : status }}</p>
    </div>
    </div>
</template>

<script>
import { formatStayDateTime } from '@/utils/date';

export default {
  name: 'ServiceCard',
  methods: {
    formatStayDateTime
    },
    props: {
        stay: {
            type: Object,
        },
        windowStart: {
            type: String,
        },
        status: {
            type: String,
        },
    }
}
</script>

<style scoped>
.card {
  display: flex;  align-items: center;
  width: 100%;
  margin: 24px;
  border-radius: 12px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.card-cancelled {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 24px;
  border-radius: 12px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  opacity: 0.67;
}

.card-image {
  width: 130px;
  height: 100%;
  border-radius: 12px 0 0 12px;
  margin-right: 14px;
  object-fit: cover;
}

.card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  font-family: 'DM Sans';
  max-width: 200px;
  margin-top: 12px;
}

.checkin-date {
  margin-top: 0;
  font-size: 16px;
  font-weight: 400;
  font-family: 'DM Sans';
  opacity: 0.67;
  max-width: 200px;
  text-transform: capitalize;
}
.card-title-cancelled {
  font-size: 18px;
  font-weight: bold;
  font-family: 'DM Sans';
  max-width: 200px;
  margin-top: 12px;
  text-decoration: line-through;
}

.checkin-date-cancelled {
  margin-top: 0;
  font-size: 16px;
  font-weight: 400;
  font-family: 'DM Sans';
  opacity: 0.67;
  max-width: 200px;
  text-decoration: line-through;
}
</style>