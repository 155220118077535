import { app } from '@/main.js'

import { get, post } from './base.js'

export async function getThreads () {
    // Returns a list of all the rental channels the user is a member of
    try {
      var headers = {headers: {'Authorization': 'Token ' + app.config.globalProperties.$cookies.get('token')}}
      const { data } = await get('api/v1/chat_threads/', headers)
      return [null, data]
      } catch (err) {
          return [err, null]
      }
  }

export async function sendMessage (message) {
    // send a new message
    try {
        var headers = { headers: { 'Authorization': 'Token ' + app.config.globalProperties.$cookies.get('token') } }
        message.thread = message.thread.id
        const { data } = await post(`api/v1/messages/create/`, message, headers)
        return [null, data]
        } catch (err) {
            return [err, null]
        }
}

export async function getThreadMessages (thread) {
    // send a new message
    try {
        var headers = {headers: {'Authorization': 'Token ' + app.config.globalProperties.$cookies.get('token')}}
        const { data } = await get(`/api/v1/chat_threads/${thread}/messages/`, headers)
        return [null, data]
        } catch (err) {
            return [err, null]
        }

}

export async function uploadFile(file) {
    try {
        console.log(`uploadFile: ${file}`)
        var headers = {headers: {'Authorization': 'Token ' + app.config.globalProperties.$cookies.get('token'), 'Content-Type': 'multipart/form-data'}}
        const formData = new FormData()
        formData.append('file', file.file)
        const { data } = await post(`api/v1/chat_file_upload/`, formData, headers)
    return [null, data];
  } catch (error) {
      console.log(error)
      console.error(error.response.data)
    return [error, null];
  }
}