<template>
    <van-steps :active="active" style="margin: 18px; margin-bottom: 0; padding-bottom: 0;" direction="vertical">
        <van-step v-for="step in steps" :key="step.id" @click="active = 0">
            <div :class="active == 0 ? 'active-step' : 'step'">
                <h3 class="step-title">{{ step.title }}</h3>
                <img :src="step.image.file" class="step-img" />
                <h3 class="step-subtitle">{{ step.description }}</h3>
            </div>
        </van-step>
    </van-steps>
</template>

<script>
export default {
    name: 'GetInSteps',
    props: {
        steps: {
            type: Array,
            default: () => []
        }
    }
}
</script>

<style scoped>
.step-title {
    text-transform: uppercase;
    font-weight: 700;
    color: var(--black);
    font-family: 'DM Sans';
    margin-bottom: 0;
    padding-bottom: 0;
}
.step-subtitle {
    font-size: 16px;
    font-weight: 400;
    font-family: 'DM Sans';
    opacity: 0.67;
    margin-top: 12px;
}
.step-img {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    border-radius: 15px;
    margin-top: 18px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>