import { app } from '@/main.js'

import { post, get, authGet } from './base.js'

export async function login (email, password) {
    // Login to the backend and store the token to cookies
    try {
        console.log(`login: ${email} ${password}`)
        const { data } = await post('api-token-auth/', {username: email, password: password})
        app.config.globalProperties.$cookies.set('token', data.token, '365d')
      return null
    } catch (err) {
          return err
      }
}

export async function loginWithMagicLink (token) {
    // Login to the backend and store the token to cookies
    try {
      console.log('loginWithMagicLink')
      const { data } = await get(`api-magic-link-auth/${token}/`)
      app.config.globalProperties.$cookies.set('token', data.token, '365d')
      return null
      } catch (err) {
          return err
      }
}


  
export async function getUserProfile () {
    // send a new message
    try {
        var headers = {headers: {'Authorization': 'Token ' + app.config.globalProperties.$cookies.get('token')}}
        const { data } = await authGet(`/api/v1/my-profile/`, headers)
        return [null, data]
        } catch (err) {
            return [err, null]
        }

}