<template>
  <chat-header :color="thread.threadType.color" :title="title"></chat-header>
  <div class="wrapper">
    <div class="messages" ref="messagesContainer">
      <div v-for="(message, i) in sortedMessages" :key="message.id">
        <chat-message :lastMessages="i > sortedMessages.length - 4" :key="message.id" :message="message">
          <component :key="message.id" :is="message.widget" :message="message"></component>
        </chat-message>
      </div>
    </div>
    <send-message-box @send="onSend" class="send-message-box"></send-message-box>
  </div>
</template>

<script>
import ChatHeader from '../components/chatview/chatheader/ChatHeader.vue'
import ChatMessage from '../components/chatview/chatmessage/ChatMessage.vue'
import TextWidget from '../components/chatview/chatwidgets/textwidget/TextWidget.vue'
import ImageWidget from '../components/chatview/chatwidgets/imagewidget/ImageWidget.vue'
import SendMessageBox from '../components/chatview/sendmessagebox/SendMessageBox.vue'

import { v4 as uuidv4 } from 'uuid';
import { mapActions } from 'vuex'

export default {
  name: 'ChatView',
  components: { ChatHeader, ChatMessage, TextWidget, ImageWidget, SendMessageBox },
  computed: {
    thread: function () {
      return this.$store.state.threads.threads.find(thread => thread.id === parseInt(this.$route.params.id))
     },
    sortedMessages() {
      // make sure the messages are ordered by date
      var messages = this.messages
      messages.sort((a, b) => {
        const timestampA = new Date(a.timestamp).getTime();
        const timestampB = new Date(b.timestamp).getTime();
        return timestampA - timestampB;
      });
      return messages
    },
    title: function () {
      // the title of the thread
      try {
        return this.$store.state.threads.threads.find(thread => thread.id === this.thread.id).title
      } catch {
        return 'Chat'
      }
    },
    messages: function () {
      // returns an array of all messages for the thread
      try {
        var messages = this.$store.state.chat.messages[this.thread.id]
        for (var i in messages) {
          messages[i].senderIsUser = messages[i].sender === this.$store.state.auth.user.id
        }
        if (!Array.isArray(messages)) {
          return []
        }
        return messages        
      } catch {
        return []
      }
    }
  },
  methods: {
    ...mapActions('chat', ['fetchThreadMessages', 'createMessage', 'syncThreadMessages', 'sendMessageWithFile']),
    subscribe() {
      // backend emits an event everytime there is an update to a channel. This will trigger an event if the update is for the current channel.
      this.$pusher.subscribe('messages')
      this.$pusher.bind('thread_updated', async data => {
        if (parseInt(data.thread) === parseInt(this.thread.id)) {
          this.fetchThreadMessages(this.thread.id)
        }
      })
    },
    scrollToBottom() {
      // every time a new message appears, automatically scroll to the bottom
      this.$nextTick(() => {
        const container = this.$refs.messagesContainer;
        container.scrollTop = container.scrollHeight;
      });
    },
    formMessage: function (sender, widget, content, thread) {
      // form a correctly formatted message
      var timestamp = new Date().toISOString()
      var tempId = uuidv4()
      return { thread: thread, sender: sender, seen: [sender], widget: widget, content: content, timestamp: timestamp, id: null, tempId: tempId }
    },
    onSend: function (message) {
      // create the message and push it to the store and backend
      var formedMessage = this.formMessage(this.$store.state.auth.user.id, message.widget, message.content, this.thread)
      this.createMessage({ thread: this.thread.id, message: formedMessage })
    }
  },
  mounted() {
    if (this.$store.state.auth.user.id == null) {
      this.$router.push('/signin')
    }
    this.syncThreadMessages(this.thread.id)
    this.scrollToBottom();
    this.subscribe()
  },
  updated() {
    this.scrollToBottom();
  },
};
</script>

<style scoped>
.avatar {
  max-width: 50px;
  height: auto;
  border-radius: 50%;
}
.content {
  width: 70%
}
.timestamp {
  font-family: 'Open Sans';
  opacity: 0.54;
  margin: 6px;
}
.messages {
  max-height: calc(100vh - 45px - 122px);
  overflow: auto;
}</style>
