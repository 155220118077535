const moment = require('moment-timezone');

export function formatDate(timestamp, userTimezone) {
  // Parse the timestamp into a moment object
  const momentObj = moment(timestamp);

  // If the user timezone is not provided, use the browser's timezone
  if (!userTimezone) {
    userTimezone = moment.tz.guess();
  }

  // If the user timezone is different from the message timezone,
  // convert the moment object to the user timezone
  if (userTimezone && userTimezone !== momentObj.tz()) {
    momentObj.tz(userTimezone);
  }

  // Calculate the difference between the timestamp and the current time
  const diffMinutes = moment().diff(momentObj, 'minutes');
  const diffHours = moment().diff(momentObj, 'hours');
  const diffDays = moment().diff(momentObj, 'days');

  // Return a human-readable string based on the difference
  if (diffMinutes < 1) {
    return 'just now';
  } else if (diffMinutes < 60) {
    return `${diffMinutes} minute${diffMinutes !== 1 ? 's' : ''} ago`;
  } else if (diffHours < 24) {
    return `${diffHours} hour${diffHours !== 1 ? 's' : ''} ago`;
  } else if (diffDays < 7) {
    return `${diffDays} day${diffDays !== 1 ? 's' : ''} ago`;
  } else {
    return momentObj.format('MMM D, YYYY');
  }
}

export function formatStayDateTime(datetime) {
  // Parses an iso datetime string into a moment object
  const momentObj = moment(datetime);
  return momentObj.format('MMM D, YYYY [at] h:mm A');
}
