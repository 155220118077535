const state = {
  activeTab: 0, // preserve tab on frontend on refresh
  threads: [],
}

const mutations = {
  setActiveTab: function (state, activeTab) {
    state.activeTab = activeTab
  },
  setThreads: function (state, threads) {
    state.threads = threads
  }
}

import { getThreads } from '@/services/chat'

const actions = {
  async fetchThreads({ commit }) {
    try {
      const [err, threads] = await getThreads()
      if (err) {
        // handle error
        return
      }
      commit('setThreads', threads)
    } catch (err) {
      // handle error
      console.error(err)
    }
  }
}

const getters = {}

export default {
    namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

