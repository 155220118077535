<template>
    <!--- outgoing message -->
    <van-row class="outgoing-row" v-if="message.senderIsUser">
    <van-row>
      <van-row class="full-width">
        <van-col class="content" cols="16">
            <slot/>
        </van-col>
      </van-row>
      <van-row class="full-width">
        <van-col cols="16">
          <h5 class="timestamp">{{ formatDate(message.timestamp) }}</h5>
        </van-col>
        <van-col v-if="lastMessages" cols="8">
          <Icon size="12" class="circle-icon" v-if="message.id === null">
            <Circle12Filled/>
          </Icon>
          <Icon class="icon" v-else>
              <Checkmark16Filled/>
            </Icon>
        </van-col>
      </van-row>
    </van-row>
    </van-row>
    <!--- incoming message -->
    <van-row class="incoming-row" justify="end" v-else>
      <van-row justify="end" class="full-width">
        <van-col class="content" cols="16">
            <slot/>
        </van-col>
        <van-col cols="8">
            <img class="avatar" :src="message.avatar" />
        </van-col>
      </van-row>
      <van-row justify="end" class="full-width">
        <h5 class="timestamp">{{ formatDate(message.timestamp) }}</h5>
      </van-row>
    </van-row>
</template>

<script>
import { formatDate } from '@/utils/date.js'
import { Icon } from '@vicons/utils'
import { Circle12Filled } from '@vicons/fluent'
import { Checkmark16Filled  } from '@vicons/fluent'

export default {
  name: 'ChatMessage',
    components: { Icon, Circle12Filled, Checkmark16Filled },
    methods: { formatDate },
    props: {
        message: {
            type: Object
      },
      lastMessages: {
          type: Boolean,
          default: false
        }
    }
}
</script>

<style scoped>
.outgoing-row {
  margin: 12px;
}
.incoming-row {
  margin: 12px;
}
.avatar {
  max-width: 50px;
  height: auto;
  border-radius: 50%;
}
.content {
  width: 70%;
  max-width: 100%;
  word-break: break-all;
}
.timestamp {
  font-family: 'Open Sans';  opacity: 0.54;
  margin: 6px;
  margin-left: 12px;
  margin-right: 12px;
}
.incoming-message {
  background-color: #E7EAED;
  padding: 12px;
  margin-right: 12px;
  border-radius: 15px 15px 0px 15px;
  box-shadow: rgba(99, 99, 99, 0.05) 0px 2px 8px 0px;
  opacity: 0.86;
}
.outgoing-message {
  background-color: #081A39;
  color: white;
  padding: 12px;
  margin-left: 12px;
  border-radius: 15px 15px 15px 0px;
  box-shadow: rgba(8, 26, 57, 0.2) 0px 2px 8px 0px;
}

.full-width {
  width: 100%;
}

.icon {
  margin-top: 8px;
  opacity: 0.45;
  color: var(--success);
}

.circle-icon {
  margin-top: 10px;
  opacity: 0.45;
}

</style>