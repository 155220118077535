import { createStore } from 'vuex'
import auth from './modules/auth'
import threads from './modules/threads'
import chat from './modules/chat'
import stays from './modules/stays'
import housekeeping from './modules/housekeeping'
/*

import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

*/

export default createStore({
  modules: {
    auth,
    threads,
    chat,
    stays,
    housekeeping
  },
  //plugins: [vuexLocal.plugin]
})