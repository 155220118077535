<template>
    <div :class="message.senderIsUser ? 'outgoing-message' : 'incoming-message'">
    {{ message.content }}
    </div>
</template>

<script>
export default {
    name: 'TextWidget',
    props: {
        message: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>
.incoming-message {
  background-color: #E7EAED;
  padding: 12px;
  margin-right: 12px;
  border-radius: 15px 15px 0px 15px;
  box-shadow: rgba(99, 99, 99, 0.05) 0px 2px 8px 0px;
  opacity: 0.86;
  overflow: hidden;
  overflow-wrap: break-word;
}
.outgoing-message {
  background-color: #081A39;
  color: white;
  padding: 12px;
  margin-left: 12px;
  border-radius: 15px 15px 15px 0px;
  box-shadow: rgba(8, 26, 57, 0.2) 0px 2px 8px 0px;
  overflow: hidden;
  overflow-wrap: break-word;
}
</style>