var content = {
    pl: {
        cleanSummary: {
            cleaningSummary: 'PODSUMOWANIE SPRZĄTANIA',
            clean: 'Czysto',
            quickClean: 'SZYBKIE SPRZĄTANIE',
            status: 'Status',
            scheduled: 'ZAPLANOWANE',
            openFrom: 'Rozpoczęcie',
            finishBy: 'Zakończenie',
            durationMinutes: 'Czas trwania (minuty)',
            pays: 'Płaca',
            locationAccess: 'LOKALIZACJA I DOSTĘP',
            buildingAccessCode: 'Kod dostępu do budynku',
            unitAccessCode: 'Kod dostępu do jednostki',
            nomaStaffContact: 'KONTAKT Z PRACOWNIKAMI NOMA',
            guestInfo: 'INFORMACJE O GOŚCIU',
            guestName: 'Imię',
            start: 'Rozpocznij',
            statusOptions: {
                scheduled: 'Zaplanowane',
                cancelled: 'Anulowane',
                in_progress: 'W trakcie',
                completed: 'Zakończone'
            }
        },
        cleaningSteps: {
            cleaningChecklist: 'Etapy sprzątania',
            finished: 'Zakończone'
        },
        submitClean: {
            finished: 'Zakończone'
        },
        reportDamage: {
            cleaningDamage: 'USZKODZENIA PODCZAS SPRZĄTANIA',
            reportDamage: 'Proszę sfotografować i natychmiast zgłosić wszelkie uszkodzenia na WhatsApp.',
            finished: 'Zakończone'
        },
        notifications: {
            fileUploadFailed: 'Nie udało się przesłać pliku. Proszę spróbować ponownie.',
            uploading: 'Przesyłanie pliku...',
            photoUploadFailed: 'Nie udało się przesłać zdjęć',
            success: 'Dziękujemy za wykonanie sprzątania!'
        },
        stepTitles: {
            cleaningSteps: 'Etapy sprzątania',
            cleaningPhotos: 'Zdjęcia sprzątania',
            damageReports: 'Zgłoszenia uszkodzeń'
        }
    }
}



export default function getContent(lang) {
    if (lang === 'pl') {
        return content.pl
    } else {
        throw new Error('Unsupported language')
    }
}