<template>
    <van-form @submit="handleSubmit">
        <van-swipe ref="van-swipe" indicator-color="white">
            <van-swipe-item v-for="(photo, i) in referencePhotos" :key="photo.id">
                <template v-if="index < referencePhotos.length">
                    <van-row style="width: 100%">
                        <h3 class="title">{{ photo.name }}</h3>
                    </van-row>
                    <van-row style="width: 100%">
                        <photo-upload :key="photo.id" @upload="handlePhotoUploaded($event, photo)" class="photo-upload" :exampleImg="getExistingCleaningPhoto(i) ? getExistingCleaningPhoto(i).img.file : photo.img.file"></photo-upload>
                    </van-row>
                </template>
                <template v-else>
                    <van-empty description="All done" />
                </template>
            </van-swipe-item>
        </van-swipe>
        <van-row class="submit-button">
            <van-button :disabled="cleaningPhotos.length < referencePhotos.length" class="gold-button-shadow" block round type="primary" native-type="submit" @click="$emit('finish')">
                <p class="button-text">{{ content.finished }}</p>
            </van-button>
        </van-row>
    </van-form>
</template>

<script>
import PhotoUpload from '@/components/housekeepingservicepage/PhotoUpload.vue'

export default {
    name: "SubmitClean",
    components: {
        PhotoUpload
    },
    watch: {
        index: function () {
            this.swipeToIndex(this.index)
        }
    },
    mounted: function () {
        this.swipeToIndex(this.index)
    },
    props: {
        housekeepingService: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            default: 0,
        },
        content: {
            type: Object,
            required: true
        }
    },
    data: function () {
        return {
            referencePhotos: this.housekeepingService ? this.housekeepingService.referencePhotos : [],
            cleaningPhotos: this.housekeepingService ? this.housekeepingService.cleaningPhotos : [],
        }
    },
    computed: {
        existingCleaningPhoto: function () {
            return this.getExistingCleaningPhoto(this.index)
        }
    },
    methods: {
        getExistingCleaningPhoto: function (index) {
            if (index > (this.referencePhotos.length - 1)) return null
            return this.cleaningPhotos.find(photo => photo.referencePhoto.id === this.referencePhotos[index].id)  
        },
        swipeToIndex: function (index) {
            this.$refs['van-swipe'].swipeTo(index)
        },
        reset: function () {
            this.cleaningPhotos = this.housekeepingService ? this.housekeepingService.cleaningPhotos : []
        },
        handlePhotoUploaded(img, referencePhoto) {
            // create the cleaning photo object to be added or updated
            if (this.existingCleaningPhoto) {
                var cleaningPhoto = {
                    id: this.existingCleaningPhoto.id,
                    referencePhoto: referencePhoto.id,
                    notes: this.existingCleaningPhoto.notes,
                    housekeepingService: this.existingCleaningPhoto.housekeepingService
                }
            } else {
                cleaningPhoto = {
                    referencePhoto: referencePhoto.id,
                    notes: '',
                    housekeepingService: this.housekeepingService.id
                }
            }
            this.$emit('photoUploaded', {cleaningPhoto: cleaningPhoto, img: img})
        },
    },
}
</script>

<style scoped>
.gold-button-shadow {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.12);
    transition: box-shadow 0.3s ease-in-out;
}

.button-text {
    font-weight: 600;
    font-size: 16px;
    color: var(--black);
}

.submit-button {
    padding: 12px;
    padding-top: 24px;
    padding-bottom: 24px;
}

.submit-button {
    padding: 12px;
    padding-top: 24px;
    padding-bottom: 24px;
}

.img-with-camera-logo {
    width: calc(100% - 48px);
    max-height: 300px;
    border-radius: 12px;
    margin: 24px;
    margin-top: 0;
    overflow: hidden;
    position: relative;
}

.img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    filter: grayscale(50%);
}

.title {
    text-transform: uppercase;
    margin: 24px 24px 12px 24px;
    padding-bottom: 0;
    font-weight: 700;
    color: var(--black);
    font-family: 'DM Sans';
}

.uploader {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
}

.photo-upload {
    margin-left: 24px;
}
</style>