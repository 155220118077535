
const state = {
    housekeepingServices: []
};

const mutations = {
    setHousekeepingServices(state, HousekeepingServices) {
        // Set the housekeeping object to the housekeeping array
        state.housekeepingServices = HousekeepingServices;
  },
}

import { getHousekeepingServices, createOrUpdateCleaningPhoto } from '@/services/housekeeping.js'

const actions = {
  async createOrUpdateCleaningPhoto({ dispatch }, photo) {
    // Call the createOrUpdateCleaningPhoto function and await its response
    const [error,] = await createOrUpdateCleaningPhoto(photo)

    // If an error occurs, throw an error with the error message
    if (error) {
      throw new Error(error.response.data.detail)
    }

    // If no error occurs, commit the setHousekeepingServices mutation with the stays as an argument
    dispatch('syncHousekeepingServices')
  },

    async syncHousekeepingServices ({ commit }) {
      // Call the getHousekeepingServices function and await its response
      const [error, stays] = await getHousekeepingServices()

      // If an error occurs, throw an error with the error message
      if (error) {
        throw new Error(error.response.data.detail)
      }

      // If no error occurs, commit the setHousekeepingServices mutation with the stays as an argument
      commit('setHousekeepingServices', stays)
    },     
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};