<template>
    <van-nav-bar :title="title" left-arrow @click-left="step === 0 ? $router.back() : step--"></van-nav-bar>
    <clean-summary :content="content.cleanSummary" @start="step++" v-if="step === 0" :housekeepingService="housekeepingService"></clean-summary>
    <cleaning-steps :content="content.cleaningSteps" @finish="step++" v-if="step === 1" :steps="housekeepingService.serviceType.steps"></cleaning-steps>
    <submit-clean :content="content.submitClean" @finish="step++" @photoUploaded="handlePhotoUploaded" :index="cleaningPhotosIndex" :housekeepingService="housekeepingService" v-if="step === 2"></submit-clean>
    <report-damage :content="content.reportDamage" @finish="handleFinished" :housekeepingService="housekeepingService" v-if="step === 3"></report-damage>
</template>

<script>
import CleanSummary from '@/components/housekeepingservicepage/CleanSummary.vue'
import CleaningSteps from '@/components/housekeepingservicepage/CleaningSteps.vue'
import SubmitClean from '@/components/housekeepingservicepage/SubmitClean.vue'
import ReportDamage from '@/components/housekeepingservicepage/ReportDamage.vue'

import getContent from '@/content/housekeepingservicepage.js'
import { mapActions } from 'vuex'
import { showFailToast, showLoadingToast, closeToast, showToast, showSuccessToast } from "vant";
import { uploadFile as uploadFileService } from '@/services/housekeeping.js'; // Import the uploadFile function from services/chat.js

export default {
    name: 'HousekeepingServicePage',
    data: function () {
        return {
            step: 0,
            cleaningPhotosIndex: 0,
        }
    },
    components: {
        CleanSummary,
        CleaningSteps,
        SubmitClean,
        ReportDamage
    },
    methods: {
        ...mapActions('housekeeping', ['syncHousekeepingServices', 'createOrUpdateCleaningPhoto']),
        uploadMediaFile: async function (file) {
            const [error, data] = await uploadFileService(file);
            if (error) {
                showToast(this.content.notifications.fileUploadFailed);
            } else {
                return data.id; // Replace the local files object with the URL returned from the backend
            }
        },
        handlePhotoUploaded: async function ({ cleaningPhoto, img }) {
            showLoadingToast({ message: this.content.notifications.uploading, duration: 0}); // Show a loading toast while the file is being uploaded
            try { 
                const fileId = await this.uploadMediaFile(img)
                cleaningPhoto.img = fileId
                this.createOrUpdateCleaningPhoto(cleaningPhoto)
                this.cleaningPhotosIndex++
                this.syncHousekeepingServices() // TODO: this is a hack to update the housekeeping service in the store
                this.$forceUpdate()
                closeToast()
            } catch (error) {
                closeToast()
                showFailToast({ message: this.content.notifications.photoUploadFailed })
            }
        },
        handleFinished: function () {
            showSuccessToast({ message: this.content.notifications.success })
            setTimeout(() => {
                this.$router.push({name: 'housekeeping'})
            }, 1000)
        }
        
    },
    computed: {
        content: () => getContent('pl'),
        housekeepingServiceID: function () {
            return parseInt(this.$route.params.id)
        },
        housekeepingService: function () {
            return this.$store.state.housekeeping.housekeepingServices.find(housekeepingService => housekeepingService.id === this.housekeepingServiceID)
        },
        title: function () {
            if (this.step === 0) {
                return this.housekeepingService.stay.title
            } else if (this.step === 1) {
                return this.content.stepTitles.cleaningSteps
            } else if (this.step === 2) {
                return this.content.stepTitles.cleaningPhotos
            } else if (this.step === 3) {
                return this.content.stepTitles.damageReports
            }
            return "Housekeeping Service"
        }
    },
}

</script>

