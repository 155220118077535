export function dedupeObjects(array) {
  const result = [];
  const uniqueIds = new Set(); // Set to keep track of unique id values
  const uniqueTempIds = new Set(); // Set to keep track of unique tempID values

  for (const obj of array) {
    const id = obj.id;
    const tempId = obj.tempId;

    // Check if the id or tempID value is already in the corresponding set
    if ((id != null && uniqueIds.has(id)) || (tempId != null && uniqueTempIds.has(tempId))) {
      // If a duplicate is found, skip this object and move on to the next one
      continue;
    }

    // If the id or tempID value is not a duplicate, add it to the corresponding set
    if (id != null) {
      uniqueIds.add(id);
    }

    if (tempId != null) {
      uniqueTempIds.add(tempId);
    }

    // Add the object to the result array
    result.push(obj);
  }

  // Return the deduplicated array
  return result;
}