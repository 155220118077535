<template>
    <div class="card">
    <img :src="img" :alt="`${title}`" class="card-image">
    <div class="card-content">
        <h2 class="card-title">{{ title }}</h2>
        <p class="checkin-date">{{ formatStayDateTime(moveIn) }}</p>
    </div>
    </div>
</template>

<script>
import { formatStayDateTime } from '@/utils/date';

export default {
  name: 'PropertyCard',
  methods: {
    formatStayDateTime
    },
    props: {
        title: {
            type: String,
            default: 'City Suite Warsaw Central'
        },
        img: {
            type: String,
            default: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/457968187.jpg?k=aec8413310f9ef1d20535e045d5e6a40e4bb4ff811a30a9ffe86e647c0ca8ba4&o=&hp=1'
      },
        moveIn: {
            type: String,
            default: '2021-08-01'
        }
    }
}
</script>

<style scoped>
.card {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 24px;
  border-radius: 12px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.card-image {
  width: 130px;
  height: 100%;
  border-radius: 12px 0 0 12px;
  margin-right: 14px;
  object-fit: cover;
}

.card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  font-family: 'DM Sans';
  max-width: 200px;
  margin-top: 12px;
}

.checkin-date {
  margin-top: 0;
  font-size: 16px;
  font-weight: 400;
  font-family: 'DM Sans';
  opacity: 0.67;
  max-width: 200px;
}
</style>