<template>
    <van-row v-for="(img, i) in message.content" :key="i" :justify="message.senderIsUser ? 'start' : 'end'">
        <van-image width="10rem"
        radius="12"
        :position="message.senderIsUser ? 'left' : 'right'"
        height="10rem"
        fit="cover"
        :class="message.senderIsUser ? 'outgoing-img' : 'incoming-img'" class="img" :src="img" />
    </van-row>
</template>

<script>
export default {
    name: 'ImageWidget',
    props: {
        message: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>
.outgoing-img {
    box-shadow: rgba(99, 99, 99, 0.05) 0px 2px 8px 0px;
    margin-left: 8px;
}
.incoming-img {
    box-shadow: rgba(99, 99, 99, 0.05) 0px 2px 8px 0px;
    margin-right: 8px;
}
</style>