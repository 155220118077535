<template>
    <div class="message-box">
        <van-row>
            <!--- allows the user to send a text message -->
            <van-field  :disabled="disableTextInput" v-model="text" autosize rows="5" :placeholder="this.files.length > 0 ? '' : 'Type to start writing a message'">
                <template #button>
                    <!--- allows the user to upload a single file like a photograph --->
                    <van-uploader :accept="'image/*'" :before-read="beforeRead" :after-read="uploadFile" :max-size="500 * 1024" @delete="resetData" @oversize="onOversize" :max-count="1" v-model="files">
                        <van-button type="default">
                            <Icon size="24">
                                <Camera20Filled />
                            </Icon>
                        </van-button>
                    </van-uploader>
            </template>
            </van-field>
        </van-row>
        <!--- emits the send signal and clears the component state --->
        <van-row justify="end">
            <van-button round loading-type="spinner" :loading="files.length != fileUrls.length" @click="handleSubmit" :class="showSubmitButton ? 'submit-button-hidden' : 'submit-button'" color="black">Send</van-button>
        </van-row>
    </div>
</template>

<script>
import { uploadFile as uploadFileService } from '@/services/chat.js'; // Import the uploadFile function from services/chat.js

import { Camera20Filled } from '@vicons/fluent'
import { Icon } from '@vicons/utils'
import { showToast } from 'vant';

export default {
    name: 'MessageBox',
    components: { Camera20Filled, Icon },
    data: () => ({
        text: '',
        files: [],
        fileUrls: [],
        disableTextInput: false
    }),
    computed: {
        showSubmitButton: function () {
            return (((this.files.length === 0) & (this.text.length === 0)) || (this.files.length != this.fileUrls.length))
        }
    },
    methods: {
        resetData: function () {
            // resets the input data
            this.text = ''
            this.files = []
            this.fileUrls = []
        },
        beforeRead: (file) => {
            if (file.type !== 'image/jpeg' & file.type !== 'image/png' & file.type !== 'image/webp') {
                showToast('Please upload an image in jpg format');
                return false;
            }
            return true;
        },
        handleSubmit: function () {
            // emit the message that is being send a clear the text
            this.$emit('send', { content: this.files.length > 0 ? this.fileUrls : this.text,  widget: this.files.length > 0 ? 'ImageWidget' : 'TextWidget' })
            this.resetData()
        },
        onOversize: () => {
            // displays an error when the user tries to uplaod a file bigger than 500kb
            showToast('File size cannot exceed 500kb');
        },
        uploadFile: async function (file) {
            console.log(file)
            const [error, data] = await uploadFileService(file);
            if (error) {
                showToast('Failed to upload the file. Please try again.');
            } else {
                this.fileUrls = [data.url]; // Replace the local files object with the URL returned from the backend
                this.disableTextInput = true;
            }
        }
    },
    watch: {
        files: function () {
            if (this.files.length > 0) {
                this.disableTextInput = true
            } else {
                this.disableTextInput = false
                this.fileUrls = []
            }
        }
    }
}
</script>

<style scoped>
.message-box {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    position: fixed;
    bottom: 0vh;
    width: 100vw;
    background-color: white;
}
.submit-button {
    width: 100%;
    margin: 12px;
    margin-top: 0px;
    font-weight: 600;
    font-size: 16px;
    transition: all 0.3s ease;
    opacity: 1.0;
}
.submit-button-hidden {
    width: 100%;
    margin: 12px;
    margin-top: 0px;
    font-weight: 600;
    font-size: 16px;
    opacity: 0;
    transition: all 0.3s ease;
}
</style>